import React, { useEffect, useState, useRef } from "react";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import './ChatComponent.scss';
import moment from "moment";
import SkeletonChatCard from "components/elements/SkeletonChatCard/SkeletonChatCard";
import _ from 'lodash';
import "moment/locale/es";
import ChatFileUploader from "../../elements/ChatFileUploader/ChatFileUploader";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ChatBubble  from '../../elements/ChatBubble/ChatBubble'
import {getFileChatAPI} from '../../../services/chat'
import { Alert, Avatar, CircularProgress, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
moment.locale("es");

const ChatComponent = (props) => {

    const { 
        loadingChats, 
        chats, 
        goToChat, 
        messages, 
        sendMessage, 
        chatForwardMessage,
        setFwdSrcChat,
        deleteMessage,
        setMessageItemSelected,
        messageItemSelected,
        chatName, 
        chatId, 
        chatType, 
        chatUserPhoto, 
        profileIdAdmin,
        messageSelected,
        setMessageSelected,
        messageReplySelected,
        setMessageReplySelected,
        showReplyMsg,
        setShowReplyMsg,
        setForwardInformation,
        loadingMessages,
        user,
    } = props;

    const [message, setMessage] = useState(undefined);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxSrc, setLightboxSrc] = useState("");
    const [tabValue, setTabValue] = useState("private");
    const [anchorEl, setAnchorEl] = useState(null);
    const [copyAlertHandler, setCopyAlertHandler] = useState(false);
    const open = Boolean(anchorEl);
    const [openContactsDialog, setOpenContactsDialog] = useState(false);

    const onTabValue = (event, newValue) => {
        setTabValue(newValue);
    };

    //const testChats = [...chats, {name:"test"}, {name:"test"}, {name:"test"}, {name:"test"}, {name:"test"}, {name:"test"}, {name:"test"}];

    const chatRef = useRef();

    const inputRef = useRef();

    const scrollToBottom = () => {
        chatRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        });
    };

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            prepareMessage()
        }
    }

    const prepareMessage = () => {
        if(message !== undefined && message.length !== 0){
            if(showReplyMsg) {
                sendMessage(message, messageItemSelected);
            } else {
                sendMessage(message);
            }
            setMessage("");
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleContactsDialogClose = () => {
        setOpenContactsDialog(false);
    }

    const handleForwardContactList = (chat) => {

        if(messageSelected !== null) {

            setForwardInformation(
                {
                    chat: chat, 
                    message: messageSelected
                }
            )
            //chatForwardMessage(chat);

        }

        setOpenContactsDialog(false);
        setAnchorEl(null);

    }

    useEffect(() => {
        if(loadingMessages == false) {
            if(messages !== undefined && messages.length !== 0) {
                setTimeout(() => scrollToBottom(), 500)
            }
        }
    }, [messages])

    useEffect(() => {
        if(lightboxSrc !== "") {
            setIsLightboxOpen(true);
        }
    }, [lightboxSrc])


    useEffect(() => {
        if(showReplyMsg == true) {
            inputRef.current.focus()
        }
    }, [showReplyMsg])

    const formatDate = (time) => {
        const now = moment();
        const messageTime = moment(time);

        if (now.date() === messageTime.date()) {
            return messageTime.format("HH:mm");
        } else if (now.date() - 1 === messageTime.date()) {
            return messageTime.calendar(now);
        } else {
            return messageTime.format("D/M/YY");
        }
        /*if (now.date() === messageTime.date()) {
            return messageTime.format("HH:mm");
        } else if (now.date() - 1 === messageTime.date()) {
            return messageTime.calendar(now);
        } else if (now.year() === messageTime.year()) {
            return messageTime.format("DD/MMM");
        } else {
            return messageTime.format("ll");
        }*/
    };
    const getMessageContent = async (message,chatId,chatType) => {
      switch (message.type) {
        case 'text':

          break;
        case 'image':
          const url = await getFileChatAPI({
            type: 'image',
            name: message.content,
            screenId: chatId,
            chatType: chatType //discussionGroup | private | public
          });
          return url;
          break;
        default:
          return message.content
      }
    }

    const getReplyToMsg = (id) => {
        let msg = null
        if(id){
            let msgFilter = messages.filter((item) => {
                return item.id == id
            });
            if(msgFilter.length >=1 ){
              msg = msgFilter[0]
            } else {
                msg = {
                    name:'Error',
                    message:{
                        type:'text',
                        content:'No encontrado'
                    }
                };
              /*try {
                msg = await getMessageById(id, chatId,chatType)
              } catch (e) {
                msg = {
                    name:'Error',
                    message:{
                      type:'text',
                      content:'No encontrado'
                    }
                };
              }*/
            }
        }
        return msg
    }

    return (
        <DashboardLayout>
            <div className="chats-wrapper">
                { isLightboxOpen === true && (
                    <Lightbox
                        mainSrc={lightboxSrc}
                        onCloseRequest={() => {
                            setIsLightboxOpen(false);
                        }
                        }
                    />
                )}
                <div style={{ overflowY: "auto" }} className="chats-contacts">
                    <div className="section-title">
                        <h1>Chats</h1>
                    </div>
                    <>
                        {chats !== undefined && chats.length !== 0 ? 
                        <div className="chats-selector">
                            <Tabs 
                                value={tabValue} 
                                onChange={onTabValue}
                                //variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                            >
                                <Tab value="private" label="Privados"></Tab>
                                <Tab value="public" label="Interés"></Tab>
                                <Tab value="discussionGroup" label="Debate"></Tab>
                            </Tabs> 
                        </div>
                        
                        : <></>}
                    </>
                    {chats !== undefined && chats.length !== 0 ? chats.filter(item => item.type === tabValue).map((item,k)=> {
                        const lastMessage = item.lastMessage;
                        return(
                            <div key={k} className={`chat-card ${item.badge !== 0 && `notification` }`} onClick={()=> goToChat(item)}>
                                <div className="photo-text-container">
                                    <img className="photo" src={item.photo} alt="" />
                                    <div className="text">
                                        <div className="name">{item.name} {item.surname}</div>
                                        <div className="message-container">
                                            
                                            { (lastMessage?.message?.type == "text" || lastMessage?.message?.type == "url") &&
                                                <div className="message-content">{lastMessage?.message?.content}</div>
                                            }

                                            { lastMessage?.message?.type == "image" &&
                                                <div className="message-content">{"Imagen"}</div>
                                            }

                                            { lastMessage?.message?.type == "audio" &&
                                                <div className="message-content">{"Audio"}</div>
                                            }

                                            { lastMessage?.message?.type == "file" &&
                                                <div className="message-content">{"Archivo"}</div>
                                            }

                                            { lastMessage?.message?.type == "delete" &&
                                                <div className="message-content">{"Mensaje eliminado"}</div>
                                            }

                                            <div className="message-date">{formatDate(lastMessage?.timestamp)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="notification-container">
                                    {item.badge !== 0 && <div className="notification">{item.badge}</div>}
                                </div>
                            </div>
                        )
                    }):
                    loadingChats && _.times(3, (i) => (
                        <SkeletonChatCard key={i} />
                    )) }
                </div>
                <div className="chats">
                    {
                        loadingMessages == true ?
                            <div class="progress-container">
                                <CircularProgress color="inherit"></CircularProgress>
                            </div>
                        :
                        <>
                            {chatName && <div className="name-container">
                                <img className="photo" src={chatUserPhoto} alt="" />
                                <div className="name">{chatName}</div>
                            </div>}
                            <div className="message-container">
                                {messages !== undefined && messages.map((item, index)=> 
                                    <ChatBubble
                                        indexKey={index}
                                        profileId={profileIdAdmin}
                                        item={item}
                                        index={index}
                                        chatRef={chatRef}
                                        chatId={chatId}
                                        isUser={item.userId === profileIdAdmin}
                                        getMessageContent={getMessageContent}
                                        messages={messages}
                                        replyTo={getReplyToMsg(item.replyTo)}
                                        chatType={chatType}
                                        onBubbleClick={() => {
                                            if(item.message?.type == "image") {
                                                setLightboxSrc(item.message.url);
                                            }
                                        }}
                                        handleClick={handleClick}
                                        setMessageSelected={setMessageSelected}
                                        setMessageItemSelected={setMessageItemSelected}
                                    ></ChatBubble>
                                )}
                            </div>
                        </>
                    }
                    
                    {chatName && 
                        <>
                        {showReplyMsg == true && <div className="reply-input">
                            <div className="reply-row">
                                <AiOutlineCloseCircle className="reply-icon" size={19} onClick={() => { setShowReplyMsg(false);setMessageItemSelected(null); }}></AiOutlineCloseCircle>
                                <div className="reply-to">{"Respuesta a " + messageItemSelected?.name}</div>
                            </div>
                            <div className="reply-message">{messageItemSelected?.message.content}</div>
                        </div>}
                        <div className="message-input">
                        <ChatFileUploader
                            chatId={chatId}
                            chatType={chatType}
                            user={user}
                        ></ChatFileUploader>
                        <input className="input" ref={inputRef} placeholder="Escribe tu mensaje aquí..." value={message} onKeyPress={handleKeyPress} onChange={(e)=> setMessage(e.target.value)}></input>
                        <img onClick={prepareMessage} className={`icon ${(message === undefined || message.length === 0) && `disabled`}`} src={require("assets/images/sendMessageIcon.png")} alt="" />
                    </div></>}
                </div>
            </div>

            <Dialog scroll="paper" onClose={handleContactsDialogClose} open={openContactsDialog}>
            <div className="forward-menu">
                <h1>Reenviar mensaje</h1>

                
                <h2>Chats</h2>
                <List>
                    { chats !== undefined && chats.length !== 0 ? chats.filter(item => item.type === "private").map( (item,k) => {
                        return(
                        <ListItem key={k} onClick={() => { handleForwardContactList(item) }}>
                            <ListItemAvatar>
                                <Avatar src={item.photo}></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.name + " " + item.surname} />
                        </ListItem>
                        )}) : <></> }
                </List>
                <h2>Grupos de debate</h2>
                <List>
                    { chats !== undefined && chats.length !== 0 ? chats.filter(item => item.type === "discussionGroup").map( (item,k) => {
                        return(
                        <ListItem key={k} onClick={() => { handleForwardContactList(item) }}>
                            <ListItemAvatar>
                                <Avatar src={item.photo}></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.name} />
                        </ListItem>
                        )}) : <></> }
                </List>
                <h2>Grupos de interés</h2>
                <List>
                    { chats !== undefined && chats.length !== 0 ? chats.filter(item => item.type === "public").map( (item,k) => {
                        return(
                        <ListItem key={k} onClick={() => { handleForwardContactList(item) }}>
                            <ListItemAvatar>
                                <Avatar src={item.photo}></Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.name} />
                        </ListItem>
                        )}) : <></> }
                </List>
            </div>
            </Dialog>        

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() =>
                {
                    setShowReplyMsg(true);
                    setMessageReplySelected(messageItemSelected);
                    setAnchorEl(null)
                }
                }>Responder</MenuItem>

                <MenuItem onClick={() => 
                { 
                    navigator.clipboard.writeText(messageSelected.content); 
                    setCopyAlertHandler(true) 
                    setAnchorEl(null)
                }
                }>Copiar</MenuItem>

                <MenuItem onClick={() => { setOpenContactsDialog(true)}}>Reenviar</MenuItem>

                {profileIdAdmin === messageItemSelected?.userId &&
                    <MenuItem onClick={() => 
                        { 
                            deleteMessage(messageItemSelected); 
                            setAnchorEl(null) 
                        }
                    }>Eliminar</MenuItem>
                }

            </Menu>

            <Snackbar open={copyAlertHandler} autoHideDuration={3000} onClose={() => setCopyAlertHandler(false)}>
                <Alert onClose={() => setCopyAlertHandler(false)}>
                    Copiado al portapapeles con éxito.
                </Alert>
            </Snackbar>

        </DashboardLayout>

    )

}

export default ChatComponent;
