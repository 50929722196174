import React, { useEffect, useState, useRef } from "react";
import ChatComponent from "../components/ChatsComponent"
import { chatLoginService, getContactsInformationService, getContactsService, getDiscussionGroupsService, getGroupsService, getPrivateChatsService, getUnreadMessagesCount,  listenChatIdService, sendMessageService, createPrivateChat, getFileChatAPI, copyFile } from "../../../services/chat";
import {getUserIdService} from '../../../services/user'
import { getLastReadLocalStorage, setLastReadLocalStorage, validURL, getUserToken } from "../../../services/utils";
import _, { trim } from "lodash";
import queryString from 'query-string';

const Chats = (props) => {

    const params = queryString.parse(props.location.search)
    const [user, setUser] = useState({});
    const [profileIdAdmin, setProfileIdAdmin] = useState(null);

    const [chats, setChats] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [loadingChats, setLoadingChats] = useState(true);

    const [chatId, setChatId] = useState(undefined);
    const [chatName, setChatName] = useState(undefined);
    const [chatUserPhoto, setChatUserPhoto] = useState(undefined);
    const [chatType, setChatType] = useState(undefined);
    const [loadingMessages, setLoadingMessages] = useState(false);

    const [messages, setMessages] = useState([]);
    const messagesListened = useRef([]);
    const listener = useRef(null);

    const [messageSelected, setMessageSelected] = useState(null);
    const [messageItemSelected, setMessageItemSelected] = useState(null);

    //const [fwdMessage, setFwdMessage] = useState(false);
    const [fwdSrcChat, setFwdSrcChat] = useState(false);
    const [forwardInformation, setForwardInformation] = useState(false);

    const [isSendingMessage, setIsSendingMessage] = useState(false);

    const [showReplyMsg, setShowReplyMsg] = useState(false)
    const [messageReplySelected, setMessageReplySelected] = useState(null);

    //listeners
    const groupListener = useRef(null);
    const discussionListener = useRef(null);
    const privateChatListener = useRef(null);
    const contactListener = useRef(null);
    //helpers
    const contactsInfo = useRef([]);
    const lastReadMessages = useRef(null);
    const activeChat = useRef(null);
    const chatsListened = useRef([]);
    const contactsListened = useRef([]);

    useEffect(() => {
        initializeChat();

        return () => {
              if(isFunction(privateChatListener.current)) privateChatListener.current();
        }
    }, [profileIdAdmin]);

    useEffect(() => {
        getMessages();

        //remove listener when the user leaves the screen
        return () => listener?.current && listener.current();
    }, [chatId]);

    useEffect(() => {
        if(forwardInformation !== false && forwardInformation?.message?.type == "text" || forwardInformation?.message?.type == "url") {
            forwardMessage(forwardInformation.chat, forwardInformation)
        } else if (forwardInformation !== false) {
            forwardFile(forwardInformation.chat, forwardInformation)
        }
    }, [forwardInformation]);

    function isFunction(functionToCheck) {
       return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    const initializeChat = async () => {
        try {
            chatsListened.current = []
            setChats([]);

            setChatId(undefined)
            setChatName(undefined)
            setChatUserPhoto(undefined)
            setChatType(undefined)
            const userMe = await getUserIdService(getUserToken());
            if(!profileIdAdmin){
              setUser(userMe);
              setProfileIdAdmin(userMe.profileId)
            } else {
              await chatLoginService();
              await getChats();
              await getLastRead();
            }

        }
        catch (e) {
            console.log('error initializing chat', e);
        }
        setLoadingChats(false)
    }

    const getChats = async () => {
        await getContactsInformation();
        groupListener.current = await getGroupsService(profileIdAdmin, addChat);
        discussionListener.current = await getDiscussionGroupsService(profileIdAdmin, addChat);
        privateChatListener.current = await getPrivateChatsService(profileIdAdmin, addChat);
        //contactListener.current = await getContactsService(addContact);
    };

    const getLastRead = async () => {
        const lastRead = await getLastReadLocalStorage();
        lastReadMessages.current = lastRead;
    };

    const addChat = async (chat) => {
        let ignoreChat = false;
        const unreadMessages = await checkUserHasUnreadMessages(chat);
        chat.badge = unreadMessages;
        const index = _.findIndex(chatsListened.current, (c) => c.id === chat.id);

        if (index >= 0) {
            chatsListened.current.splice(index, 1);
        }
        if (chat.type === "private") {
            const participants = chat.participants;
            const userToTalkTo = participants[0] === profileIdAdmin ? participants[1] : participants[0];
            const contactInformationIndex = _.findIndex(contactsInfo.current, (c) => c.profileId === userToTalkTo);
            if (contactInformationIndex >= 0) {
                chat = {
                    ...chat,
                    ...contactsInfo.current[contactInformationIndex],
                    photo: contactsInfo.current[contactInformationIndex] && contactsInfo.current[contactInformationIndex].image ? contactsInfo.current[contactInformationIndex].image.url : null,
                };
            } else ignoreChat = true;
        }
        if (!ignoreChat) {
            chatsListened.current.unshift(chat);
            chatsListened.current = _.orderBy(chatsListened.current, ({ updatedAt }) => updatedAt || 0, "desc");
            setChats([...chatsListened.current]);
        }
    };

    const checkUserHasUnreadMessages = async (chat) => {
        const chatId = chat.id;
        const lastReadTime = lastReadMessages.current[chatId];
        if (!lastReadTime || activeChat.current === chatId) {
            lastReadMessages.current[chat.id] = new Date().getTime();
            setLastReadLocalStorage(lastReadMessages.current);
            return 0;
        }

        const userIsUpdated = lastReadTime >= chat?.lastMessage?.timestamp;
        if (!userIsUpdated) {
            const count = await getUnreadMessagesCount(chatId, lastReadTime, chat.type);
            return count;
        }
        return 0;
    };


    const getContactsInformation = async () => {
        try {
            const response = await getContactsInformationService();
            // console.log(response)
            contactsInfo.current = response;
            return response;
        } catch (err) {
            console.log(err);
        }
    };

    const editChat = (chatId, data) => {
        const index = _.findIndex(chatsListened.current, (c) => c.id === chatId);
        if (index >= 0) {
            chatsListened.current[index] = { ...chatsListened.current[index], ...data };
        }
        setChats([...chatsListened.current]);
    };

    const goToChat = async (chat) => {
        let chatToGo = chat;

        setLoadingMessages(true);

        try {
            if (chat.type === "contact") {
                //if it's a contact, not a chat
                chatToGo = await createPrivateChat(chat, profileIdAdmin);
                const participants = chatToGo.participants;
                const userToTalkTo = participants[0] === profileIdAdmin ? participants[1] : participants[0];
                const contactInformationIndex = _.findIndex(contactsInfo.current, (c) => c.profileId === userToTalkTo);
                chatToGo = {
                    ...contactsInfo.current[contactInformationIndex],
                    photo: contactsInfo.current[contactInformationIndex] && contactsInfo.current[contactInformationIndex].image ? contactsInfo.current[contactInformationIndex].image.url : null,
                    ...chatToGo,
                };
            }
            activeChat.current = chatToGo.id;
            editChat(chatToGo.id, { badge: 0 });
            lastReadMessages.current[chatToGo.id] = new Date().getTime();
            setLastReadLocalStorage(lastReadMessages.current);
        } catch (e) {
            console.log(e);
        } finally {

            let titleOfChat = chatToGo.name;
            if(chatToGo.type === "private") {
                titleOfChat = titleOfChat + " " + chatToGo.surname;
            }

            setChatId(chatToGo.id);
            setChatName(titleOfChat);
            setChatUserPhoto(chatToGo.photo);
            setChatType(chatToGo.type);
            setFwdSrcChat(chatToGo);
        }
    };

    const deleteMessage = async (message) => {
        /**
         * this function sends a new message with type = delete and content = the message id you want to delete
         */

        if (messageItemSelected.userId !== profileIdAdmin) {
            return;
        }
        
        const timestamp = new Date().getTime();
        const deleteMessage = {
            name: user.name,
            timestamp,
            message: { type: 'delete', content: message.id },
            userId: user.profileId,
        };

        await sendMessageService(chatId, deleteMessage, chatType);
        //toggleChatOptions();
    };


    const addMessage = async (message) => {
        const senderId = message.userId;
        const contactInformationIndex = _.findIndex(contactsInfo.current, (c) => c.profileId === senderId);

        if (message.message?.type === 'delete') {
            const deletedMessageId = message.message.content;
            //console.log('deletedMessageId', deletedMessageId);
            const deletedMessagePosition = _.findIndex(messagesListened.current, function (m) { return m.id == deletedMessageId; });
            messagesListened.current.splice(deletedMessagePosition, 1);
            //deleteChatMessage(chatId, deletedMessageId);
            let clone = _.cloneDeep(messagesListened.current);
            clone = messagesListened.current.sort((a, b) => a.timestamp - b.timestamp);
            setMessages(clone);
            return;
        }

        if (contactInformationIndex !== undefined && contactsInfo.current[contactInformationIndex]) {
            message = {
                ...message,
                //name: contactsInfo.current[contactInformationIndex].name,
                photo: contactsInfo.current[contactInformationIndex].image ? contactsInfo.current[contactInformationIndex].image.url : null,
            };
        }

        if (message?.message?.type === 'image' || message?.message?.type === 'file' || message?.message?.type === 'audio') {

            const urlPromise = getFileChatAPI({
                type: message?.message?.type,
                name: message?.message?.content,
                screenId: chatId,
                chatType: chatType,
            }).then((url) => {
                message.message.url = url;
                //addChatMessage(chatId, message);

                let messagePosition = _.findIndex(messagesListened.current, function (m) { return m.id == message.id; });
                messagesListened.current[messagePosition] = message;
                let clone = _.cloneDeep(messagesListened.current);
                setMessages(clone);
            });
        }

        messagesListened.current.push(message);
        setMessages([...messagesListened.current]);
        setLoadingMessages(false);
    };

    const getMessages = async () => {
        messagesListened.current = [];
        setMessages([])
        if (!chatId) return;
        listener.current = listenChatIdService(chatId, addMessage, chatType);
    };

    const sendMessage = async (text, replyingTo = null) => {

        if(isSendingMessage) {
            return;
        } else {
            setIsSendingMessage(true);
        }

        if(trim(text).length === 0) {
            return;
        }

        let typeText = "text";
        if(validURL(text)) {
            typeText = 'url';
        }

        const timestamp = new Date().getTime();
        const message = {
            userId:user.profileId,
            name: user.name+' '+user?.surname,
            timestamp,
            message: { type: typeText, content: text },
            userId: profileIdAdmin,
            replyTo: (showReplyMsg==true) ? replyingTo?.id : null
        };

        await sendMessageService(chatId, message, chatType);
        setIsSendingMessage(false);
        setShowReplyMsg(false);
        setMessageItemSelected(null);
    };

    /*const chatForwardMessage = async (chat) => {

        console.log("AFTER", fwdMessage, messageSelected)
        if(fwdMessage !== false) {
            if(fwdMessage?.type == "text" || fwdMessage?.type == "url") {
                forwardMessage(chat);
            } else {
                forwardFile(chat);
            }
        } else {
            return;
        }
        
        await goToChat(chat);
    }*/

    const getFileChat = async (message) => {
        return await getFileChatAPI({
            type: 'file',
            name: message.content,
            screenId: chatId,
            chatType: chatType //discussionGroup | private | public
        });
    }

    const forwardMessage = async (chat, fwdMessage) => {


        if(isSendingMessage) {
            return;
        } else {
            setIsSendingMessage(true);
        }

        if (fwdMessage === false) {
            return;
        }


        if (trim(fwdMessage.message.content).length === 0) return;

        let typeText = "text";
        if (validURL(fwdMessage.message.content)) typeText = 'url';



        const timestamp = new Date().getTime();
        const message = {
            name: user.name+' '+user?.surname,
            timestamp,
            message: { type: fwdMessage.message.type, content: fwdMessage.message.content },
            userId: user.profileId,
            replyTo: null
        };

        await sendMessageService(chat.id, message, chat.type);

        setIsSendingMessage(false);
        setFwdSrcChat(false);
        setForwardInformation(false);
        //setFwdMessage(false);
    };

    const forwardFile = async (chat, fwdMessage) => {

        if(isSendingMessage) {
            return;
        } else {
            setIsSendingMessage(true);
        }

        console.log("fwdMessage", fwdMessage)

        if (trim(fwdMessage.message.content).length === 0) return;

        const res = await copyFile(fwdMessage.message.content,fwdSrcChat,chat);
        if(res){
          const timestamp = new Date().getTime();
          const message = {
              name: user.name,
              timestamp,
              message: { type: fwdMessage.message.type, content: fwdMessage.message.content },
              userId: user.profileId,
              replyTo: null
          };

          await sendMessageService(chat.id, message, chat.type);
          setIsSendingMessage(false);
          setFwdSrcChat(false);
          setForwardInformation(false);
          //setFwdMessage(false);
        }
    };

    return <ChatComponent
                    loadingChats={loadingChats}
                    chats={chats}
                    goToChat={goToChat}
                    messages={messages}
                    sendMessage={sendMessage}
                    //chatForwardMessage={chatForwardMessage}
                    chatName={chatName}
                    chatType={chatType}
                    chatId={chatId}
                    chatUserPhoto={chatUserPhoto}
                    user={user}
                    profileIdAdmin={profileIdAdmin}
                    messageSelected={messageSelected}
                    setMessageItemSelected={setMessageItemSelected}
                    messageItemSelected={messageItemSelected}
                    setFwdSrcChat={setFwdSrcChat}
                    setForwardInformation={setForwardInformation}
                    deleteMessage={deleteMessage}
                    showReplyMsg={showReplyMsg}
                    setShowReplyMsg={setShowReplyMsg}
                    setMessageSelected={setMessageSelected}
                    messageReplySelected={messageReplySelected}
                    setMessageReplySelected={setMessageReplySelected}
                    loadingMessages={loadingMessages}
                    />

}

export default Chats;
