const BASE_URL = process.env.REACT_APP_API_URL || "https://api.dev.netchain.pitagorinesgroup.com/";

export const endpoints = {
    user: BASE_URL + "user",
    users: BASE_URL + "users",
    courses: BASE_URL + "courses",
    credentials: BASE_URL + "credentials",
    profiles : BASE_URL + "profiles",
    notifications: BASE_URL + "notifications",
    admin: BASE_URL + "admin",
    payments: BASE_URL + "payments",
    paymentMethod: BASE_URL + "payment-methods",
    suggestions: BASE_URL + "suggestions",
    adminDonationCertificates: BASE_URL + "admin/donation-certificates",
    donationCertificates: BASE_URL + "donation-certificates",
    membershipCertificates: BASE_URL + "certificates",
    get_firebase_admin_token: BASE_URL + "chat/admin/login",
    get_firebase_token: BASE_URL + "chat/login",
    chat_interest: BASE_URL + "chat/interest-groups",
    chat_see_file: BASE_URL + "chat/see-file",
    chat_copy_file: BASE_URL + "chat/copy-file",
    chat_debate: BASE_URL + "chat/debate-groups",
    chat_send_file: BASE_URL + "/chat/file",
    get_contacts: BASE_URL + "/chat/contacts",
    chat_get_active_interes_groups: BASE_URL + "/chat/active-interest-groups",
    chat_get_my_debate_groups: BASE_URL + "/chat/my-debate-groups",
    youtube: BASE_URL + "youtube",
    webhook: BASE_URL + "chat/webhook",
    content: BASE_URL + "content"
    /*get_firebase_admin_token: BASE_URL + "/chat/admin/login"*/
};
