import React from "react";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { Router, Switch } from "react-router-dom";
import history from "./services/history";
import Chats from "./components/Chats/containers/Chats";

// Router Components
import PublicRoute from "components/elements/PublicRoute/PublicRoute";
import PrivateRoute from "components/elements/PrivateRoute/PrivateRoute";

// Auth Routes
import Login from "./components/Login/containers/Login";


// Dashboard (all users) Routes
import Youtube from "./components/Youtube/containers/Youtube"
import YoutubeHidden from "./components/YoutubeHidden/containers/YoutubeHidden"
import Videos from "components/Videos/containers/Videos";

import "./App.scss";

function App() {
    return (
        <Provider store={store}>
            <Router history={history}>
                <div className="App">
                    <Switch>
                        <PublicRoute restricted component={Login} path="/" exact />
                        <PublicRoute restricted component={Login} path="/login" />

                        <PrivateRoute component={Videos} path="/category/:name/:subcategory?" />
                        <PrivateRoute component={Chats} path="/chats" />

                    </Switch>
                </div>
            </Router>
        </Provider>
    );
}

export default App;
