import React, { useState } from "react";
import "./MenuAccordion.scss";
import { Link, withRouter } from "react-router-dom";
import { Accordion } from "react-bootstrap";

const MenuAccordion = (props) => {
    const { data } = props;

    /*                {routes.map((route, i) => {
        let active = route.routeLink.includes(currentPath+props.location.search) ? "active" : "";
        return (
        <div key={i} className={`sidebar-item ${active}`}>
            <Link to={route.routeLink} key={i} className="sidebar-item-wrapper">
                <div className="sidebar-label">{route.label}</div>
            </Link>
            {route.externalLink ? (
                <a className="sidebar-external-link" href={route.externalLink} target="_blank">
                    <FaExternalLinkAlt />
                </a>
            ) : null}
        </div>
        )
    })} */

    return (
        <div className="menu-accordion">
            <Accordion defaultActiveKey={['0']}>
            {data.categories.length > 0 ? <Accordion.Item><Accordion.Header>Contenido</Accordion.Header><Accordion.Body>{data.categories.map((category, i) => {
                    if(category.subcategories) {
                        return (<Accordion.Item key={category.name} eventKey={i}>
                            <Accordion.Header>{category.name}</Accordion.Header>
                            <Accordion.Body>
                            {category.subcategories.map((subcategory, n) => {
                                let routeLink = "/category/" + encodeURI(category.name) + "/" + encodeURI(subcategory.name);
                                //let active = routeLink.includes(currentPath+props.location.search) ? "active" : "";
                                return(
                                    <div key={subcategory.name} className={`sidebar-item `}>
                                        <Link to={routeLink} key={n} className="sidebar-item-wrapper">
                                            <div className="sidebar-label">{subcategory.name}</div>
                                        </Link>
                                    </div>
                                    )
                            })}
                            </Accordion.Body>
                        </Accordion.Item>)
                    } else {
                        let routeLink = "/category/" + encodeURI(category.name);
                        return (<Accordion.Item key={category.name} eventKey={i}>
                            <Accordion.Header className="menu-category">
                                <Link to={routeLink} key={1}>{category.name}</Link>
                            </Accordion.Header>
                        </Accordion.Item>)
                    }
            })}</Accordion.Body></Accordion.Item>:null}
            </Accordion>
        </div>
    );
};

export default MenuAccordion;
