import React, { useState } from "react";
import { AiFillFile, AiOutlineDown, AiOutlineDownload } from 'react-icons/ai';
import AudioPlayer from 'material-ui-audio-player';
import moment from "moment";
import "./ChatBubble.scss";

const ChatBubble = (props) => {
    const { item, index, profileId, chatRef, chatId, getMessageContent, indexKey, isUser, messages, chatType, onBubbleClick, handleClick, setMessageSelected, setMessageItemSelected, replyTo} = props;
    //const [optionsOpen, setOptionsOpen] = useState(false);

    //const isUser = item.userId === profileId
    const isLastMessage = (messages.length - 1 == index ? chatRef : null)

    const renderMessage = (message) => {

        if(message?.type == "image" || message?.type == "file" || message?.type == "audio" || message?.type == "text" ) {

            return (
                <div className="menu-container">

                    { replyTo !== null && 
                        <div className="message-reply-to">
                            <div className="message-reply-who">{"En respuesta a " + replyTo.name }</div>
                            <div className="message-reply-content">{replyTo.message.content}</div>
                        </div>    
                    }

                    { message.type === "text" &&
                        <span className="message-line">{message.content}</span>
                    }

                    { message.type === "image" && 
                        <img src={message.url}></img>
                    }

                    { message.type === "audio" &&
                        <div className={"audio-player"}>
                            <AudioPlayer 
                                src={message.url}
                                volume={false}
                                loop={false}
                                width={"300px"}
                                elevation={0}
                            />
                        </div>
                    }

                    { message.type === "file" && 
                        <span className="file-link">
                            <AiFillFile size={19}/>
                            <a className="download-text" href={message.url} target="_blank">Descargar archivo</a>
                            <AiOutlineDownload size={19} />
                        </span>                
                    }

                    <a onClick={(event) => { handleClick(event); setMessageSelected(message); setMessageItemSelected(item)}} className="arrow"><AiOutlineDown /></a>
                </div>
            )

        }  else {
            return (
                <>{message.content}</>
            )
        }
    }

    return(
            <div key={indexKey} className={"chat-bubble bubble-" + item.message?.type } style={{marginBottom: 10}} onClick={onBubbleClick} ref={isLastMessage}>
                {index === 0 && <div style={{textAlign:"center", paddingTop:20, color:"#C4C4C4"}}>{moment(item.timestamp).format("L")}</div>}
                {isUser ?
                <div className="message isUser">
                    <div className="text-container">
                        <div className="message-text isUser">{renderMessage(item.message)}</div>
                        <div className="message-timestamp isUser">{moment(item.timestamp).format("lll")}</div>
                    </div>
                    <img className="photo isUser" src={item.photo}  alt="" />
                </div>
                :
                <div className="message">
                    <img className="photo" src={item.photo} alt="" />
                    <div className="text-container">
                        <div className="message-text">
                            {chatType !== "private" && <div className="message-author">{item.name}</div>}
                            <span>{renderMessage(item.message)}</span>
                        </div>
                        <div className="message-timestamp">{moment(item.timestamp).format("lll")}</div>
                    </div>
                </div>
                }
            </div>
    )
};

export default ChatBubble;