import React, { useState } from "react";
import {ImAttachment} from "react-icons/im";
import { getFileBuffer } from "utils/imageFormatter";
import { getFileChatService, uploadFileService, sendMessageService } from "services/chat";
import _, { trim } from "lodash";

import "./ChatFileUploader.scss";

const ChatFileUploader = (props) => {
    const { chatId, chatType, user} = props;
    //const [optionsOpen, setOptionsOpen] = useState(false);

    const uploadFileHandler = async (file) => {
        try {

            const imageService = await getFileChatService({ key: file.name, screenId: chatId, chatType, mimeType: file.type });

            const fileBuffer = await getFileBuffer(file);

            await uploadFileService({ url: imageService.url, body: fileBuffer, mimeType: file.type });

            if(file.type.includes("image")) {

                sendFile(file.name, 'image');

            } else {
                
                sendFile(file.name, 'file');

            }


        } catch (error) {
            console.error("Error file upload", error)
        }
    }

    const sendFile = (file, typeText) => {
        if (trim(file).length === 0) return;

        const timestamp = new Date().getTime();
        const message = {
            name: user.name,
            timestamp,
            message: { type: typeText, content: file },
            userId: user.profileId,
        };

        sendMessageService(chatId, message, chatType);
    };

    return (                        
    <>
        <label className="chat-file-uploader-label" htmlFor="chat-attachment-button">
            <ImAttachment
                size={32}
                className="chat-file-uploader"
            ></ImAttachment>
        </label>
        <input
        style={{
            display: 'none',
        }}
        type="file"
        id="chat-attachment-button"
        onChange={(event) =>{ uploadFileHandler(event.target.files[0])}}
        />
    </>
    );
};

export default ChatFileUploader;