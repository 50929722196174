import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "redux/actions/userActions";
import LoginComponent from "../components/LoginComponent";
import { socketUrl } from "services/user"
import { io } from "socket.io-client";
import QRious from 'qrious';
import { setLocalVideos, getUserVideosService } from "services/videos";

const Login = (props) => {
    const { updateUser, history } = props;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [socketId, setSocketId] = useState('');
    const [socket, setSocket] = useState(io(socketUrl));

    const canvas = useRef(null);


    useEffect(() => {
        socketConnect();
    });

    /*const submit = async (values) => {
        setLoading(true);

        const data = {
            email: values.email,
            password: values.password
        }

        try {
            const response = await loginService(data);
            updateUser(response);
            history.push("/youtube-videos");
            setLoading(false);
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };*/


    const socketConnect = async () => {

      socket.on('connect', () => {
        console.log("CONNECTED");
        console.log("SOCKET", socket);

        setSocketId(socket.id);

        socket.on('QRLoginToken', function (data) {
            setLoading(true);
            console.log("LOGGED IN");

            updateUser({token: data});

            try {
                (async() => {
                    let videos = await getUserVideosService();

                    // console.log("VIDEOS", videos);

                    setLocalVideos(videos);

                    //history.push("category/" + encodeURI(videos.categories[0].name))
                    history.push("chats")

                    setLoading(false);
                })();
            } catch(e) {
                console.log("ERROR: ", e);
            }

        })
      });
    }

    const QRCode = ({text, size}) => {
        useEffect(() => {
          console.log("TEXT", text);
          if (canvas != null && canvas.current != null) {
            let qr = new QRious({
              element: canvas.current,
                value: text,
                size: size
            });
          }
        }, [socketId]);
        return(<canvas ref={canvas}></canvas>);
    }

    return (<LoginComponent
        {...props}
        loading={loading}
        error={error}
        history={history}
        socket={socket}
        socketId={socketId}
        QRCode={QRCode}
    />);
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ updateUser }, dispatch);

export default withRouter(connect(null, mapDispatchToProps)(Login));
