import { db, storageRef, firebaseApp, auth } from "../firebase";
import moment from 'moment';
import customFetch from "./customFetch";
import { getUserToken, getUserState } from "./utils";
import { endpoints } from "./endpoints";
import _ from 'lodash';


export const getDebateGroupsService = async () => {
    const groupsPromises = await db.collection("discussionGroup").get();
    const groupsPromisesWithMessages = groupsPromises.docs.map(async debateGroup => {
        const messagesPromises = await db.collection("discussionGroup").doc(debateGroup.id).collection("messages").get();
        const messagesCount = messagesPromises.docs.length;
        const groupAPI = await getDebateGroupAPI(debateGroup.id);
        let memberIds = []
        let description = ''
        let startDate = new Date();
        let endDate = new Date();
        let active = false;
        if(groupAPI && groupAPI[0]){
          memberIds = groupAPI[0].memberIds;
          description = groupAPI[0].description;
          startDate = groupAPI[0].startDate;
          endDate = groupAPI[0].endDate;
          active = groupAPI[0].active;
        }

        return {
            ...debateGroup.data(),
            messagesCount,
            id: debateGroup.id,
            description,
            memberIds,
            startDate,
            endDate,
            active
        }
    });
    const groups = await Promise.all(groupsPromisesWithMessages);
    return groups;
}

const getDebateGroupAPI = async (id) => {
    try {
        let response = await customFetch(`${endpoints.chat_debate}?filter[where][debateGroupId]=${id}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createDebateGroupService = async (newGroupData) => {
    try {
        const groupPromise = await db.collection("discussionGroup").add(newGroupData);
        const groupData = {
            ...newGroupData,
            id: groupPromise.id,
            messagesCount: 0
        };
        return groupData;
    } catch (err) {
        throw err;
    }
}

export const createDebateGroupAPI = async (debateGroupId,name,description,memberIds,startDate,endDate) => {
    try {

        const response = await customFetch(endpoints.chat_debate, {
            method: "POST",
            token: getUserToken(),
            bodyReq: { debateGroupId,name,description,memberIds,startDate,endDate},
        });

        return response;
    } catch (e) {
        console.log("error createDebateGroupAPI");
        throw e;
    }
};

export const updateDebateGroupService = async (groupData, groupId) => {
    try {
        delete groupData.messagesCount;
        await db.collection("discussionGroup").doc(groupId).update(groupData);
        const messagesPromises = await db.collection("discussionGroup").doc(groupId).collection("messages").get();
        const messagesCount = messagesPromises.docs.length;

        const updatedGroupData = {
            ...groupData,
            messagesCount
        };

        return updatedGroupData;
    } catch (err) {
        throw err;
    }
}

export const updateDebateGroupDataAPI = async (debateGroupId,name,description,memberIds,startDate,endDate) => {
    try {

        let response = await customFetch(`${endpoints.chat_debate}/${debateGroupId}`, {
            method: "PATCH",
            token: getUserToken(),
            bodyReq: { debateGroupId,name,description,memberIds,startDate,endDate},
        });

        return response;
    } catch (e) {
        console.log("error updateDebateGroupDataAPI");
        throw e;
    }
};

export const deleteDebateGroupService = async (groupId) => {
    try {
        const deleteSuccessful = await db.collection("discussionGroup").doc(groupId).delete();
        deleteDebateGroupAPI(groupId)
        return deleteSuccessful;
    } catch (err) {
        throw err;
    }
}

const deleteDebateGroupAPI = async (id) => {
    try {
        let response = await customFetch(`${endpoints.chat_debate}/${id}`, {
            method: "DELETE",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};


export const archiveDebateGroupService = async (groupId) => {
    try {
        let response = await customFetch(`${endpoints.chat_debate}/${groupId}/archive`, {
            method: "PATCH",
            token: getUserToken(),
        })

        if(response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}


export const getInterestGroupsService = async () => {
    const groupsPromises = await db.collection("groups").get();
    const groupsPromisesWithMessages = groupsPromises.docs.map(async interestGroup => {
        const messagesPromises = await db.collection("groups").doc(interestGroup.id).collection("messages").get();
        const messagesCount = messagesPromises.docs.length;
        const groupAPI = await getInterestGroupAPI(interestGroup.id);
        let active = false;
        let description = '';
        if(groupAPI && groupAPI[0]){
          active = groupAPI[0].active
          description = groupAPI[0].description
        }
        return {
            ...interestGroup.data(),
            messagesCount,
            id: interestGroup.id,
            active,
            description
            // endDate
        }
    });
    const groups = await Promise.all(groupsPromisesWithMessages);
    return groups;
}

const getInterestGroupAPI = async (id) => {
    try {
        let response = await customFetch(`${endpoints.chat_interest}?filter[where][interestGroupId]=${id}`, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const createInterestGroupService = async (newGroupData) => {
    try {
        const groupPromise = await db.collection("groups").add(newGroupData);
        const groupData = {
            ...newGroupData,
            id: groupPromise.id,
            messagesCount: 0
        };
        return groupData;
    } catch (err) {
        throw err;
    }
}

export const createInterestGroupAPI = async (interestGroupId,name,description,active=true) => {
    try {

        const response = await customFetch(endpoints.chat_interest, {
            method: "POST",
            token: getUserToken(),
            bodyReq: { interestGroupId,name, description,active},
        });

        return response;
    } catch (e) {
        console.log("error createInterestGroupAPI");
        throw e;
    }
};

export const updateInterestGroupService = async (groupData, groupId) => {
    try {
        delete groupData.messagesCount;
        await db.collection("groups").doc(groupId).update(groupData);
        const messagesPromises = await db.collection("groups").doc(groupId).collection("messages").get();
        const messagesCount = messagesPromises.docs.length;

        const updatedGroupData = {
            ...groupData,
            messagesCount
        };

        return updatedGroupData;
    } catch (err) {
        throw err;
    }
}

export const updateInterestGroupDataAPI = async (interestGroupId,name,description,active=true) => {
    try {

        let response = await customFetch(`${endpoints.chat_interest}/${interestGroupId}`, {
            method: "PATCH",
            token: getUserToken(),
            bodyReq: { interestGroupId,name,description, active},
        });

        return response;
    } catch (e) {
        console.log("error updateInterestGroupDataAPI");
        throw e;
    }
};

export const deleteInterestGroupService = async (groupId) => {
    try {
        const deleteSuccessful = await db.collection("groups").doc(groupId).delete();
        deleteInterestGroupAPI(groupId)
        return deleteSuccessful;
    } catch (err) {
        throw err;
    }
}

const deleteInterestGroupAPI = async (id) => {
    try {
        let response = await customFetch(`${endpoints.chat_interest}/${id}`, {
            method: "DELETE",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
};

export const archiveInterestGroupService = async (groupId) => {
    try {
        let response = await customFetch(`${endpoints.chat_interest}/${groupId}/archive`, {
            method: "PATCH",
            token: getUserToken(),
        })

        if(response.error) throw response;

        return response;
    } catch (err) {
        throw err;
    }
}

export const chatLoginService = async () => {
    try {

        const response = await customFetch(endpoints.get_firebase_token, {
            method: "GET",
            token: getUserToken(),
        });

        // console.log("FIREBASE TOKEN", response);

        const firebaseResponse = await auth.signInWithCustomToken(response.token).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // ...
            console.log("Error al logearse a FIREBASE", errorCode, errorMessage);

          });


        return firebaseResponse;
    } catch (e) {
        console.log("error at logging in firebase");
        throw e;
    }
};

export const getContactsInformationService = async () => {
    try {
        const response = await customFetch(endpoints.get_contacts, {
            method: "GET",
            token: getUserToken(),
        });

        if (response.error) throw response;

        return response;
    } catch (err) {
        console.log(err)
        throw err;
    }
};

export const getContactsService = async (callback) => {
    const observer = db.collection("contacts").onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            const newContact = { ...change.doc.data(), id: change.doc.id, type: "contact" };

            callback(newContact);
        });
    });
    return observer;
};

export const getPrivateChatsService = async (userId, callback) => {
    const observer = db
        .collection("privateChats")
        .where("participants", "array-contains", userId)
        // .orderBy("updatedAt", "desc")
        .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
                const newChat = { ...change.doc.data(), id: change.doc.id, type: "private" };

                callback(newChat);
            });
        });
    return observer;
};

export const getUnreadMessagesCount = async (chatId, lastReadTimestamp, chatType) => {
    const unreadMessages = await db
        .collection(chatType === "public" ? "groups" : "privateChats")
        .doc(chatId)
        .collection("messages")
        .where("timestamp", ">", lastReadTimestamp)
        .get();
    return unreadMessages.docs.length;
};

export const listenChatIdService = (id, callback, chatType) => {
    const observer = db
        .collection(chatType === "public" ? "groups" : chatType === "discussionGroup" ? "discussionGroup" : "privateChats")
        .doc(id)
        .collection("messages")
        .orderBy("timestamp", "asc")
        .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
                const newMessage = { ...change.doc.data(), id: change.doc.id };
                callback(newMessage);
            });
        });
    return observer;
};

export const sendMessageService = async (chatId, message, chatType) => {
    const document = await db
        .collection(chatType === "public" ? "groups" : chatType === "discussionGroup" ? "discussionGroup" : "privateChats")
        .doc(chatId)
        .collection("messages")
        .add(message);
    message.id = document.id;

    await db.collection(chatType === "public" ? "groups" : chatType === "discussionGroup" ? "discussionGroup" : "privateChats")
        .doc(chatId)
        .update({ lastMessage: message, updatedAt: new Date().getTime() });

    const chatDB = await db
        .collection(chatType === "public" ? "groups" : chatType === "discussionGroup" ? "discussionGroup" : "privateChats")
        .doc(chatId)
        .get();

    const participants = chatDB.data().participants.filter((p) => p !== message.userId);

    sendPushNotification(chatId,chatDB, participants, chatType);

};


const sendPushNotification = async (chatId,chatDB, receiverIds, chatType,notifText = "Tienes un nuevo mensaje") =>{


    try {
      const user = getUserState();

      let chatRoom = '';
      let notifTitle = '';

      const chatName = chatDB.data().name;
      switch (chatType) {
          case "public": chatRoom = 'interest'; notifTitle = `${user.name} ${user.surname}@${chatName}`; break;
          case "private": chatRoom = 'private'; notifTitle = `${user.name} ${user.surname}`; break;
          case "discussionGroup": chatRoom = 'debate'; notifTitle = `${user.name} ${user.surname}@${chatName}`; break;
          default: break;
      }

      await customFetch(endpoints.webhook, {
          method: "POST",
          token: getUserToken(),
          bodyReq: {
              receiverIds,
              chatRoom,
              chatId,
              title: notifTitle,
              message: notifText
          },
      });
    } catch (error) {
        console.log("error sendPushNotification",error)
    }
}


export const createPrivateChat = async (contact, userId) => {
    if (!userId || !contact.id) return;
    const chatKey1 = `${userId}|AND|${contact.id}`;
    const chatKey2 = `${contact.id}|AND|${userId}`;
    const chatExists = (await getPrivateChat(chatKey1)) || (await getPrivateChat(chatKey2));
    if (chatExists) return chatExists;
    else {
        const newChatData = {
            participants: [userId, contact.id],
            lastMessage: {},
            timestamp: new Date().getTime(),
            updatedAt: new Date().getTime(),
        };
        const doc = await db.collection("privateChats").doc(chatKey1).set(newChatData);
        return { ...newChatData, id: chatKey1 };
    }
};

export const getPrivateChat = async (chatId) => {
    let pvChat = await db.collection("privateChats").doc(chatId).get();
    if (pvChat.exists) {
        pvChat = { ...pvChat.data(), id: chatId, type: "private" };
    } else pvChat = null;

    return pvChat;
};
export const getFileChatAPI = async ({ name, screenId, chatType, source = "s3", type }) => {

    try {
        const user = getUserState();

        let chatRoom = '';
        switch (chatType) {
            case "discussionGroup": chatRoom = 'debate'; break;
            case "private": chatRoom = 'private'; break;
            case "public": chatRoom = 'interest'; break;
            default: break;
        }

        const body = { name, screenId, chatRoom, source };

        const response = await customFetch(endpoints.chat_see_file, {
            method: "POST",
            token: user.token,
            bodyReq: body,
        });

        // if (type === 'image') {
        //     const imgCached = await FileSystem.downloadAsync(
        //         response.url,
        //         FileSystem.documentDirectory + name
        //     );
        //     return imgCached.uri;
        // }

        return response.url;
    } catch (error) {
        console.error("error getFileChatAPI", error)
    }
};

export const getFileChatService = async ({ key, screenId, chatType, mimeType, source = "s3" }) => {
    try {
        const user = getUserState();

        let chatRoom = '';
        switch (chatType) {
            case "public": chatRoom = 'interest'; break;
            case "private": chatRoom = 'private'; break;
            case "discussionGroup": chatRoom = 'debate'; break;
            default: break;
        }

        const body = { key, screenId, chatRoom, mimeType, source };

        const response = await customFetch(endpoints.chat_send_file, {
            method: "POST",
            token: user.token,
            bodyReq: body,
        });

        return response;
    } catch (error) {
        console.error("error getFileChatService", error)
    }
};

export const uploadFileService = async ({ url, body, mimeType }) => {
    try {
        let response = await fetch(url, {
            method: "put",
            body: body,
            headers: {
                "Content-Type": mimeType,
                "x-amz-acl": "private",
                "Content-Encoding": "base64",
            },
        });

        return JSON.stringify(response.ok);
    } catch (error) {
        console.error("error uploadFileService", error)
    }
};

export const getGroupsService = async (userId, callback) => {
    try {
        // const lastRead = await getLastReadLocalStorage();
        const groups = await getAvailableGroupsService();
        if (!groups || groups.length <= 0) return null;
        const groupsIds = groups.map((g) => g.interestGroupId);

        const observer = db
            .collection("groups")
            .where(firebaseApp.firestore.FieldPath.documentId(), 'in', groupsIds)
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {

                    const groupIndex = _.findIndex(groups, function (g) { return g.interestGroupId == change.doc.id; });
                    const newChat = { ...change.doc.data(), id: change.doc.id, description: groups[groupIndex].description, actionType: change.type };

                    callback(newChat);
                });
            });
        return observer;
    } catch (e) {
        console.error("error getGroupsService", e);
        throw e;
    }
};

const getAvailableGroupsService = async () => {
    try {
        const user = getUserState();

        const response = await customFetch(endpoints.chat_get_active_interes_groups, {
            method: "GET",
            token: user.token,
        });
        return response;
    } catch (e) {
        console.error("error getAvailableGroupsService", e);
        throw e;
    }
}

const getDiscussionGroups = async () => {
    try {
        const user = getUserState();

        const response = await customFetch(endpoints.chat_get_my_debate_groups, {
            method: "GET",
            token: user.token,
        });
        return response;
    } catch (e) {
        console.error("error getDiscussionGroups", e);
        throw e;
    }
}

export const getDiscussionGroupsService = async (userId, callback) => {
    try {
        // const lastRead = await getLastReadLocalStorage();
        const groups = await getDiscussionGroups();
        if (!groups || groups.length <= 0) return null;
        const groupsIds = groups.map((g) => g.debateGroupId);
        const observer = db
            .collection("discussionGroup")
            .where(firebaseApp.firestore.FieldPath.documentId(), 'in', groupsIds)
            .onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    const groupIndex = _.findIndex(groups, function (g) { return g.debateGroupId == change.doc.id; });
                    const newChat = { ...change.doc.data(), id: change.doc.id, description: groups[groupIndex].description, actionType: change.type, participants: change.doc.data().memberIds };

                    callback(newChat);
                });
            });
        return observer;
    } catch (e) {
        console.error("error getDiscussionGroupsService", e);
        throw e;
    }
};

export const copyFile = async (name, src, dest) => {
    try {
      //console.log(name)
      //console.log(src)
      const user = getUserState();
      let srcChatRoom = '';
      let destChatRoom = '';
      switch (src.type) {
          case "discussionGroup": srcChatRoom = 'debate'; break;
          case "private": srcChatRoom = 'private'; break;
          case "public": srcChatRoom = 'interest'; break;
          default: break;
      }
      switch (dest.type) {
          case "discussionGroup": destChatRoom = 'debate'; break;
          case "private": destChatRoom = 'private'; break;
          case "public": destChatRoom = 'interest'; break;
          default: break;
      }
      const body = {
        src:{ name:name, screenId:src.id, chatRoom:srcChatRoom, mimeType:'', source:'' },
        dest:{ name:name, screenId:dest.id, chatRoom:destChatRoom, mimeType:'', source:'' }
      };
      const response = await customFetch(endpoints.chat_copy_file, {
          method: "POST",
          token: user.token,
          bodyReq: body,
      });
      return true;
    } catch (e) {
      return false;
    }
  
  
  }